import React from "react";
import "./style.css";

export const LandingPage = (): JSX.Element => {
  return (
    <div className="landing-page">
      <div className="div">
        <footer className="footer">
          <div className="overlap-group">
            <div className="footer-text">
              <div className="footer-text-2">
                <div className="contact">
                  <div className="div-wrapper">
                    <div className="text-wrapper">Contact</div>
                  </div>
                </div>
                <div className="all-rights-reserved">
                  <p className="text-wrapper-2">2024 © MemeWorks Studios, Inc. All rights reserved.</p>
                </div>
                <div className="text-wrapper-3">MemeWorks Studios, Inc.</div>
              </div>
            </div>
          </div>
        </footer>
        <div className="landing-page-bottom">
          <div className="contact-us-section">
            <div className="contact-us-text">
              <div className="group">
                <div className="text-wrapper-4">Contact Us</div>
              </div>
              <div className="group-2">
                <p className="p">Or wait until it contacts you.</p>
              </div>
            </div>
            <div className="contact-us-form">
              <div className="name-input">
                <img className="vector" alt="Vector" src="/img/vector-2.svg" />
                <div className="group-3">
                  <div className="text-wrapper-2">Name</div>
                </div>
              </div>
              <div className="email-input">
                <img className="img" alt="Group" src="/img/group-3.png" />
                <div className="group-4">
                  <div className="text-wrapper-2">Email</div>
                </div>
              </div>
              <div className="send-message-button">
                <div className="overlap-group-2">
                  <div className="text-wrapper-5">Send message</div>
                  <img className="frame" alt="Frame" src="/img/frame-3.svg" />
                </div>
              </div>
              <div className="message-input">
                <img className="group-5" alt="Group" src="/img/group-2.png" />
                <div className="group-6">
                  <div className="text-wrapper-2">Message</div>
                </div>
              </div>
            </div>
          </div>
          <img className="frame-2" alt="Frame" src="/img/frame-2.svg" />
        </div>
        <img className="divider" alt="Divider" src="/img/divider.png" />
        <div className="landing-page-top">
          <div className="main-text">
            <div className="group-7">
              <div className="overlap-group-wrapper">
                <div className="overlap-group-3">
                  <div className="text-wrapper-6">They shut it</div>
                  <div className="text-wrapper-7">down for</div>
                  <div className="text-wrapper-8">being too</div>
                  <div className="text-wrapper-9">powerful...</div>
                </div>
              </div>
              <div className="text-wrapper-10">Or so they thought.</div>
            </div>
          </div>
          <div className="coming-soon-button">
            <div className="overlap">
              <div className="text-wrapper-11">Coming Soon</div>
              <img className="frame-3" alt="Frame" src="/img/frame-1.svg" />
            </div>
          </div>
          <div className="computer-image">
            <div className="overlap-2">
              <img className="group-8" alt="Group" src="/img/group-1.png" />
              <div className="speech-bubble">
                <div className="speech-bubble-text">
                  <div className="hello-again-dave-wrapper">
                    <div className="hello-again-dave">
                      Hello
                      <br />
                      again,
                      <br />
                      Dave.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="header">
          <div className="header-content">
            <img className="frame-4" alt="Frame" src="/img/frame.svg" />
            <p className="memeworks-studios">
              <span className="span">Meme</span>
              <span className="text-wrapper-12">Works</span>
              <span className="text-wrapper-13">&nbsp;</span>
              <span className="text-wrapper-14">Studios</span>
              <span className="text-wrapper-15">,</span>
              <span className="text-wrapper-13">&nbsp;</span>
              <span className="text-wrapper-16">Inc</span>
              <span className="text-wrapper-15">.</span>
            </p>
          </div>
        </header>
      </div>
    </div>
  );
};
